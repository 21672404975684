<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="row align-items-center justify-content-between">
               <div class="col-md-6 col-lg-6 col-xl-4">
                  <img src="/assets/images/crypto-conversion.webp" alt="Crypto Conversion" title="Crypto Conversion" class="img-fluid">
               </div>
               <div class="col-md-6 col-lg-6 col-xl-6 pt-4 pt-md-0">
                  <div class="text-center text-md-end">
                     <h1 class="mb-0">{{contentlist.crypto_conversion_content1}}</h1>

                     

                     <!-- APEC Start -->
                     <p class="d-block mt-4 mb-0" v-if="entityName == 'apec'">
                           {{contentlist.crypto_conversion_apec_content2}}
                     </p>
                     <!-- APEC End -->

                     <!-- Global Start -->
                     <p class="d-block mt-4 mb-0" v-else>
                        {{contentlist.crypto_conversion_global_content2}}
                     </p>
                     <!-- Global End -->

                     <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium mt-4">{{contentlist.join_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <a href="#GainAccess" class="medium d-block text-center mt-4 downArrow">
      <img src="/assets/images/arrow-down.webp" alt="Arrow Down" title="Arrow Down" class="img-fluid">
      </a>
      <section class="blockElement" id="GainAccess">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-9 text-center">
                  <h2 class="mb-0">{{contentlist.crypto_conversion_content3}}</h2>
                  <p class="d-block mt-4 mb-0">{{contentlist.crypto_conversion_content4}}</p>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement pt-1">
         <Carousel class="globalPayoutCarousel mt-4" :wrap-around="true" :settings="settings" :autoplay="2000">
            <Slide key="1">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-hns.webp" alt="crypto box hns" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="2">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-doge.webp" alt="crypto box doge" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="3">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-aave.webp" alt="crypto box aave" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="4">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-algo.webp" alt="crypto box algo" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="5">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-bch.webp" alt="crypto box algo" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="6">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-hns.webp" alt="crypto box hns" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="7">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-doge.webp" alt="crypto box doge" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="8">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-hns.webp" alt="crypto box hns" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="9">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-doge.webp" alt="crypto box doge" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="10">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-aave.webp" alt="crypto box aave" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="11">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-algo.webp" alt="crypto box algo" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="12">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-bch.webp" alt="crypto box algo" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="13">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-hns.webp" alt="crypto box hns" class="img-fluid" /> 
               </div>
            </Slide>
            <Slide key="14">
               <div class="carousel__item">
                  <img src="/assets/images/crypto-box-doge.webp" alt="crypto box doge" class="img-fluid" /> 
               </div>
            </Slide>
            <template #addons>
               <Navigation />
               <Pagination />
            </template>
         </Carousel>
         <div class="d-block text-center mt-5"><a :href="static_vars.merchantPortalLinks[entityName].register" class="themeBtn medium">{{contentlist.register_now_content}}</a></div>
      </section>
      <section class="blockElement lightgreybg">
         <div class="container">
            <h2 class="mb-0 text-center">{{contentlist.crypto_conversion_content5}}</h2>
            <div class="d-flex justify-content-center conversionModelMainBox">
               <div class="conversionModelBox round-sm text-center me-0 me-md-4 me-lg-5">
                  <div class="conversionModelIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto bg-white">
                     <img src="/assets/images/model-flexible.webp" alt="Flexible" class="img-fluid">
                  </div>
                  <h5 class="m-0 lh-base pt-3 pt-lg-4">{{contentlist.crypto_conversion_content6}}</h5>
                  <p class="m-0 lh-base pt-3">{{contentlist.crypto_conversion_content7}}</p>
               </div>
               <div class="conversionModelBox round-sm text-center me-0 me-md-4 me-lg-5">
                  <div class="conversionModelIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto bg-white">
                     <img src="/assets/images/model-transparent.webp" alt="Transparent" class="img-fluid">
                  </div>
                  <h5 class="m-0 lh-base pt-3 pt-lg-4">{{contentlist.crypto_conversion_content8}}</h5>
                  <p class="m-0 lh-base pt-3">{{contentlist.crypto_conversion_content9}}</p>
               </div>
               <div class="conversionModelBox round-sm text-center me-0 me-md-4 me-lg-5">
                  <div class="conversionModelIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto bg-white">
                     <img src="/assets/images/model-reliable.webp" alt="Reliable" class="img-fluid">
                  </div>
                  <h5 class="m-0 lh-base pt-3 pt-lg-4">{{contentlist.crypto_conversion_content10}}</h5>
                  <p class="m-0 lh-base pt-3">{{contentlist.crypto_conversion_content11}}</p>
               </div>
               <div class="conversionModelBox round-sm text-center me-0 me-md-4 me-lg-5">
                  <div class="conversionModelIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto bg-white">
                     <img src="/assets/images/model-universal.webp" alt="Universal" class="img-fluid">
                  </div>
                  <h5 class="m-0 lh-base pt-3 pt-lg-4">{{contentlist.crypto_conversion_content12}}</h5>

                 

                  <!-- APEC Start -->
                  <p class="m-0 lh-base pt-3" v-if="entityName == 'apec'">{{contentlist.crypto_conversion_apec_content13}}</p>
                  <!-- APEC End -->

                   <!-- Global Start -->
                  <p class="m-0 lh-base pt-3" v-else>{{contentlist.crypto_conversion_global_content13}}</p>
                  <!-- Global End -->
               </div>
               <div class="conversionModelBox round-sm text-center">
                  <div class="conversionModelIcon rounded-pill mt-5 d-flex align-items-center justify-content-center m-auto bg-white">
                     <img src="/assets/images/model-seamless.webp" alt="Seamless" class="img-fluid">
                  </div>
                  <h5 class="m-0 lh-base pt-3 pt-lg-4">{{contentlist.crypto_conversion_content14}}</h5>
                  <p class="m-0 lh-base pt-3">{{contentlist.crypto_conversion_content15}}</p>
               </div>
            </div>
            <div class="d-block text-center mt-5">
               <router-link :to="entityPath+'/pricing'" class="themeBtn medium">{{contentlist.crypto_conversion_content16}}</router-link>
            
            </div>
         </div>
      </section>
      <section class="blockElement pb-0">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-9 text-center">
                  <h2 class="mb-0">{{contentlist.crypto_conversion_content17}}</h2>
                  <p class="d-block mt-4 mb-0">{{contentlist.crypto_conversion_content18}}</p>
               </div>
            </div>
            <div class="tab d-flex justify-content-center mt-5 hideMobile">
               <ul class="nav nav-pills">
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">{{contentlist.crypto_conversion_content19}}</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">{{contentlist.crypto_conversion_content20}}</a>
                  </li>
               </ul>
            </div>
            <div class="tabContent mt-4 mt-lg-5">
               <div class="showTabs-in-mob" :class="[{'active':tab == 1}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 1">
                     {{contentlist.crypto_conversion_content19}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 1" class="pt-4 pt-lg-0">
                  <div class="row">
                     <div class="col-md-7">
                        <div class="ps-0 ps-md-5">
                         
                           <!-- APEC Start -->
                           <p class="mt-3 mt-md-5" v-if="entityName == 'apec'">{{contentlist.crypto_conversion_apec_content21}}</p>
                           <!-- APEC End -->
                           <!-- Global Start -->
                           <p class="mt-3 mt-md-5" v-else>{{contentlist.crypto_conversion_global_content21}}</p>
                           <!-- Global End -->
                           <ul class="listIcon mt-4">
                              <li>{{contentlist.crypto_conversion_content22}}</li>
                              <li>{{contentlist.crypto_conversion_content23}}</li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-md-5 d-flex justify-content-end">
                        <img src="/assets/images/auto-conversion.webp" alt="auto conversion" class="img-fluid ConversionImg" width="350">
                     </div>
                  </div>
                  <div class="mt-5">
                     <h3 class="mb-0 text-center mb-5">{{contentlist.how_it_works_content}}</h3>
                     <div class="d-flex justify-content-between align-items-center howWorksMainBox">
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/crypto-deposit.webp" alt="crypto deposit" title="crypto deposit" class="img-fluid">
                           <p class="d-block mt-4 mb-0">{{contentlist.crypto_conversion_content24}}</p>
                        </div>
                        <div class="text-center howWorksArrow">
                           <img src="/assets/images/right-arrow.webp" alt="right arrow" title="right arrow" class="img-fluid">
                        </div>
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/crypto-wallet.webp" alt="crypto wallet" title="crypto wallet" class="img-fluid">
                           
                           <!-- APEC Start -->
                           <p class="d-block mt-4 mb-0" v-if="entityName == 'apec'">{{contentlist.crypto_conversion_apec_content25}}</p>
                           <!-- APEC End -->
                           <!-- Global Start -->
                           <p class="d-block mt-4 mb-0" v-else>{{contentlist.crypto_conversion_global_content25}}</p>
                           <!-- Global End -->
                        </div>
                        <div class="text-center howWorksArrow">
                           <img src="/assets/images/right-arrow.webp" alt="right arrow" title="right arrow" class="img-fluid">
                        </div>
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/crypto-account.webp" alt="crypto account" title="crypto account" class="img-fluid">
                           <p class="d-block mt-4  mb-0">{{contentlist.crypto_conversion_content26}}</p>
                        </div>
                     </div>
                     <div class="d-block text-center mt-5"><a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">{{contentlist.join_now_content}}</a></div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 2}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 2">
                     {{contentlist.crypto_conversion_content20}}
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 2" class="pt-4 pt-lg-0">
                  <div class="row">
                     <div class="col-md-7">
                        <div class="ps-0 ps-md-5">
                           <ul class="listIcon mt-5">
                              <li>{{contentlist.crypto_conversion_content27}}</li>
                              <li>{{contentlist.crypto_conversion_content28}}</li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-md-5 d-flex justify-content-end">
                        <img src="/assets/images/manual-conversion.webp" alt="manual conversion" class="img-fluid ConversionImg" width="350">
                     </div>
                     
                  </div>
                  <div class="mt-5">
                     <h3 class="mb-0 text-center mb-5">{{contentlist.how_it_works_content}}</h3>
                     <div class="d-flex justify-content-between align-items-center howWorksMainBox">
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/from-currency.webp" alt="from currency" title="from currency" class="img-fluid">
                           <h6 class="d-block mt-4">{{contentlist.crypto_conversion_content29}}</h6>
                        </div>
                        <div class="text-center howWorksArrow">
                           <img src="/assets/images/right-arrow.webp" alt="right arrow" title="right arrow" class="img-fluid">
                        </div>
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/exchange-rate.webp" alt="exchange rate" title="exchange rate" class="img-fluid">
                           <h6 class="d-block mt-4">{{contentlist.crypto_conversion_content30}}</h6>
                        </div>
                        <div class="text-center howWorksArrow">
                           <img src="/assets/images/right-arrow.webp" alt="right arrow" title="right arrow" class="img-fluid">
                        </div>
                        <div class="text-center howWorks align-self-start">
                           <img src="/assets/images/to-currency.webp" alt="to currency" title="to currency" class="img-fluid">
                           <h6 class="d-block mt-4">{{contentlist.crypto_conversion_content31}}</h6>
                        </div>
                     </div>
                     <div class="d-block text-center mt-5"><a :href="static_vars.merchantPortalLinks[entityName].register" class="themeBtn medium">{{contentlist.join_now_content}}</a></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement">
         <div class="container">
            <div class="mt-5 pt-3">
               <div class="row">
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content1}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content2}} <br> {{contentlist.flexible_box_content3}}</p>
                        </div>
                        <router-link :to="entityPath+'/payout'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.find_out_more_content}}</router-link>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content5}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content6}}<br> {{contentlist.flexible_box_content7}}</p>
                        </div>
                        <router-link :to="entityPath+'/crypto-conversion'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.find_out_more_content}}</router-link>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content8}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content9}}</p>
                        </div>
                        <router-link :to="entityPath+'/contact-us'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.contact_us_content}}</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="static_vars.merchantPortalLinks[entityName].register" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import { commonAllmixins } from '@/plugins/commonAll'   //anamica
import "vue3-carousel/dist/carousel.css";
   import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
   export default ({
      mixins: [ commonAllmixins],   //anamica
      name: "Autoplay",
      data() {
         return {
            tab: 1,
            settings: {
               snapAlign: 'start',
               breakpoints: {
                  320: {
                     itemsToShow: 1,
                     itemsToScroll:2,
                     snapAlign: 'start',
                  },
                  576: {
                     itemsToShow: 2,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  768: {
                     itemsToShow: 3,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  992: {
                     itemsToShow: 4,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1200: {
                     itemsToShow: 5,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1400: {
                     itemsToShow: 6,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1600: {
                     itemsToShow: 7,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
               },
            },
            
         };
      },
      created(){
         console.log(this.contentlist)
      },
      components: { Carousel, Slide, Pagination, Navigation },
   });
   
</script>